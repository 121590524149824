import { Controller } from "stimulus";
import $ from "jquery";
import "@/vendor/magnify";

export default class ZoomController extends Controller {
  static targets = [ ]

  initialize() {
    // Wait for image to load before initializing zoom
    const $image = $(".zoom", this.element);
    
    if ($image.length) {
      if ($image[0].complete) {
        this.initializeZoom($image);
      } else {
        $image.on('load', () => this.initializeZoom($image));
      }
    }
  }

  initializeZoom($image) {
    $image.magnify({
      speed: 200,
      touchBottomOffset: 0,
      mobileCloseEvent: "click",
      limitBounds: false,
      initialPosition: { x: 0, y: 0 },
      onZoomMove: this.handleZoomMove.bind(this)
    });
  }

  handleZoomMove(e) {
    try {
      // First try mouse coordinates as default
      let coords = {
        x: e.pageX,
        y: e.pageY
      };

      // Check for touch event
      if (e.originalEvent?.touches?.[0]) {
        coords = {
          x: e.originalEvent.touches[0].pageX,
          y: e.originalEvent.touches[0].pageY
        };
      }

      return coords;

    } catch (error) {
      console.warn('Touch handling error in zoom controller:', error);
      // Fallback to mouse coordinates if touch handling fails
      return {
        x: e.pageX || 0,
        y: e.pageY || 0
      };
    }
  }
}