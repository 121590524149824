import { Controller } from "stimulus";
import $ from "jquery";
import UIkit from "uikit";

export default class FlipUtilityController extends Controller {
  initialize() {
  }

  static targets = [ "selectButton", "boxy", "marky" ]

  selectOrUnselectAll() {
    const button = this.selectButtonTarget;

    // Select all the flips on the page
    if (button.value == "none") {
      button.value = "all"
      button.innerHTML = "Deselect All Inserts"

      for (const box of this.boxyTargets) {
        box.checked = true;
      }

      for (const mark of this.markyTargets) {
        $(mark).addClass('overlay-checked').removeClass('overlay-unchecked');
      }

      // Deselect all the flips on the page
    } else if (button.value == "all") {
      button.value = "none"
      button.innerHTML = "Select All Inserts"

      for (const box of this.boxyTargets) {
        box.checked = false;
      }

      for (const mark of this.markyTargets) {
        $(mark).addClass('overlay-unchecked').removeClass('overlay-checked');
      }
    }

  }

  checkOrUncheckit() {
    const flip = event.currentTarget
    const flip_id = flip.dataset.id
    const checkboxy = document.querySelectorAll(`[data-flip-id="${flip_id}"]`)[0];
    const checkmarky = document.querySelectorAll(`[data-mark-id="${flip_id}"]`)[0];

    if (checkboxy.checked) {
      checkboxy.checked = false;
      $(checkmarky).addClass('overlay-unchecked').removeClass('overlay-checked');
    } else {
      checkboxy.checked = true;
      $(checkmarky).addClass('overlay-checked').removeClass('overlay-unchecked');
    }
  }

  notify() {
    UIkit.notification({
      message: '<span uk-icon=\'icon: check\'></span> Your flip inserts will download to your device.',
      pos: 'top-right',
      timeout: 3000,
      cls: 'text-main'
    })
  }


}
