import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["publisherSelect", "docSelect"]

  initializePanel() {
    console.log("[ProvenanceFilter] initializePanel() called");
    // If you need any setup, do it here.
  }

  fetchDocs(event) {
    // Debug: see if this method is invoked
    console.log("[ProvenanceFilter] fetchDocs triggered", event);

    const publisherId = this.publisherSelectTarget.value;
    // Debug: confirm we have a publisherId
    console.log("[ProvenanceFilter] Selected publisherId: ", publisherId);

    if (!publisherId) {
      // Clear out the docSelect
      console.log("[ProvenanceFilter] No publisherId found; resetting docSelect");
      this.docSelectTarget.innerHTML = "<option value=''>-- select a publisher first --</option>";
      return;
    }
    // Make a GET request to your PublishersController
    fetch(`/publishers/${publisherId}/reference_documents`)
      .then(resp => {
        // Debug: confirm the request was made
        console.log(`[ProvenanceFilter] Response status: ${resp.status}`);
        return resp.json();
      })
      .then(data => {
        // Debug: see the data we got
        console.log("[ProvenanceFilter] Documents returned:", data);

        let options = "<option value=''>-- pick one --</option>";
        data.forEach(doc => {
          options += `<option value="${doc.id}">${doc.title}</option>`;
        });
        this.docSelectTarget.innerHTML = options;

        // Debug: confirm the new dropdown contents
        console.log("[ProvenanceFilter] docSelectTarget HTML: ", this.docSelectTarget.innerHTML);
      })
      .catch(err => {
        console.error("[ProvenanceFilter] Error fetching reference docs:", err);
      });
  }

  addFilter() {
    console.log("[ProvenanceFilter] addFilter() called");
    const docId = this.docSelectTarget.value;
    console.log("[ProvenanceFilter] Document to filter out: ", docId);

    if (!docId) { 
      alert("Please choose a reference document first.");
      return;
    }

    // Hide all the .js-provenance-prow elements whose data-reference-document-id = docId
    const allProvenanceRows = document.querySelectorAll(".js-provenance-prow");
    console.log("[ProvenanceFilter] Number of provenance rows found: ", allProvenanceRows.length);

    allProvenanceRows.forEach(row => {
      const rowDocId = row.getAttribute("data-reference-document-id");
      if (rowDocId === docId) {
        console.log(`[ProvenanceFilter] Hiding row for reference_document_id=${rowDocId}`);
        row.style.display = "none";
      }
    });
  }
} 