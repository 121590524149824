import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    prevUrl: String,
    nextUrl: String
  }

  connect() {
    this.handleKeyPress = this.handleKeyPress.bind(this)
    document.addEventListener('keydown', this.handleKeyPress)
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeyPress)
  }

  handleKeyPress(event) {
    if (event.target.tagName === 'TEXTAREA' || event.target.tagName === 'INPUT') {
      return
    }

    if (event.key === 'ArrowLeft' && this.prevUrlValue) {
      Turbo.visit(this.prevUrlValue)
    } else if (event.key === 'ArrowRight' && this.nextUrlValue) {
      Turbo.visit(this.nextUrlValue)
    }
  }
} 