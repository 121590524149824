import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["displayText", "formContainer", "toggleButton", "displayContainer"]
  static values = { state: String }

  toggleForm(event) {
    event.preventDefault()

    if (this.stateValue === "closed") {
      this.formContainerTarget.hidden = false
      this.displayContainerTarget.hidden = true
      this.stateValue = "open"
    } else {
      this.formContainerTarget.hidden = true
      this.displayContainerTarget.hidden = false
      this.stateValue = "closed"
    }
  }

  submitForm(event) {
    // The form will submit automatically via Turbo
    // You can add any additional logic here if needed
  }
} 